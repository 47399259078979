import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from "../components/slider"
// import { Link } from "gatsby"
// import Image from "../components/image"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SlideQuery {
      slides: allAirtable(
        filter: { table: { eq: "Slides" } }
        sort: { fields: recordId, order: DESC }
      ) {
        nodes {
          data {
            Title
            Headline
            Button_Label
            Background {
              localFiles {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            Icon {
              localFiles {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 128) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          recordId
        }
      }
    }
  `)

  const {
    slides: { nodes },
  } = data
  return (
    <Layout noHeader noFooter>
      <SEO title="We specialize in Digital Transformation" />
      <Slider nodes={nodes} />
    </Layout>
  )
}

export default IndexPage
