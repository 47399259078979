import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Carousel from "react-bootstrap/Carousel"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import styles from "./slider.module.scss"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

const Slider = ({ nodes }) => {
  return (
    <>
      <Carousel
        className={styles.container}
        controls={false}
        pauseOnHover={true}
      >
        {nodes.map((item, i) => (
          <Carousel.Item key={i} className={styles.item}>
            <BackgroundImage
              Tag="section"
              className={styles.backgroundImage}
              fluid={item.data.Background.localFiles[0].childImageSharp.fluid}
              backgroundColor={`#040e18`}
            >
              <Container fluid className={`h-100 ${styles.sidePanelContainer}`}>
                <Row
                  className={`h-100 d-inline-block text-white ${styles.sidePanelWindow}`}
                >
                  <h3
                    className={`align-self-start pb-0 mb-0 ${styles.slideTitle}`}
                  >
                    {item.data.Title}
                  </h3>
                  <Col
                    className={`h-100 d-flex flex-column text-white justify-content-center ${styles.sidePanelWindowContent}`}
                  >
                    <Img
                      fluid={item.data.Icon.localFiles[0].childImageSharp.fluid}
                      className={`mb-3 ${styles.Icon}`}
                    />
                    <h2 className="align-self-center">{item.data.Headline}</h2>
                    <Link to="/solutions/">
                      <Button
                        className={`${styles.button}`}
                        variant="outline-light"
                      >
                        {item.data.Button_Label}
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </BackgroundImage>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  )
}

Slider.propTypes = {
  nodes: PropTypes.array,
}

Slider.defaultProps = {
  siteTitle: ``,
}

export default Slider
